import React from "react"
import SwaggerUI from 'swagger-ui/dist/swagger-ui-bundle.js';
import { Logo, UnlockIconHTML, LockIconHTML, ArrowIcon } from './images'

import 'swagger-ui/dist/swagger-ui.css'
import './css/styles.scss'

document.head.innerHTML += '<meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0">'

class AugmentingLayout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      infoIsClosed: false,
      isAuthorized: false
    }
  }

  componentDidMount() {
    // Get authorization state through Swagger API
    const { getSystem } = this.props;
    if (getSystem) {
      try {
        const authorizations = getSystem().authSelectors.authorized();
        
        // Check if there are any active authorizations
        const isAuthorized = authorizations && Object.keys(authorizations.toJS()).length > 0;
        
        this.setState({ isAuthorized });
        document.body.className = isAuthorized ? 'sl-authorized' : 'sl-not-authorized';
      } catch (e) {
        console.error("Error accessing authorization state:", e);
      }
    }
  }

  getInfo() {
    const { specSelectors } = this.props
    const info = specSelectors.info()
    if (
      !info ||
      !info._list ||
      !info._list._tail ||
      !info._list._tail.array
    ) return
    const infoArray = info._list._tail.array
    if (!infoArray.length) return
    return infoArray.reduce((acc , [key, value]) => ({
      ...acc,
      [key]: value
    }), {})
  }

  render() {
    const { infoIsClosed, isAuthorized } = this.state
    const { getComponent } = this.props
    const { description, version } = (this.getInfo() || {})
    const BaseLayout = getComponent("BaseLayout", true)
    return (
      <div className="sl-wraper">
        <div className="sl-header">
          <div className="sl-header-info">
            API
            <div className="sl-version sl-info-label">
              {version}
            </div>
            <div className="sl-OAS3 sl-info-label">
              OAS3
            </div>
          </div>
          <Logo className="sl-logo" />
        </div>
        {!!description && <div className={'sl-info-top-box' + (infoIsClosed ? ' sl-closed' : '')}>
          <div
            className="sl-arrow"
            onClick={() => this.setState({ infoIsClosed: !infoIsClosed })}
          >
            <ArrowIcon />
          </div>

          <div dangerouslySetInnerHTML={{ __html: description }} />
        </div>}
        <BaseLayout />
      </div>
    )
  }
}

// A simpler approach that works with Swagger UI's component system
const CustomAuthorizeBtn = (Original, system) => class CustomButton extends React.Component {
  render() {
    // We need to encode both versions of the icon for different states
    const whiteUnlockIcon = encodeURIComponent(UnlockIconHTML.replace(/fill="[^"]*"/g, 'fill="white"'));
    const whitelockIcon = encodeURIComponent(LockIconHTML.replace(/fill="[^"]*"/g, 'fill="white"'));
    
    const blueUnlockIcon = encodeURIComponent(UnlockIconHTML.replace(/fill="[^"]*"/g, 'fill="#0055cc"'));
    const blueLockIcon = encodeURIComponent(LockIconHTML.replace(/fill="[^"]*"/g, 'fill="#0055cc"'));
    
    // We'll wrap the original button and customize it
    return (
      <div className="custom-authorize-wrapper">
        <Original {...this.props} className="authorize btn" />
        <style jsx="true">{`
          .custom-authorize-wrapper .authorize svg {
            display: none;
          }
          .custom-authorize-wrapper .authorize::before {
            content: "";
            display: inline-block;
            background-image: url("data:image/svg+xml,${whiteUnlockIcon}");
            width: 16px;
            height: 16px;
            margin-right: 10px;
            background-size: contain;
            background-repeat: no-repeat;
          }
          .custom-authorize-wrapper .authorize.locked::before {
            background-image: url("data:image/svg+xml,${whitelockIcon}");
          }
          
          /* Change icon color on hover to match text */
          .custom-authorize-wrapper .authorize:hover::before {
            background-image: url("data:image/svg+xml,${blueUnlockIcon}");
          }
          .custom-authorize-wrapper .authorize.locked:hover::before {
            background-image: url("data:image/svg+xml,${blueLockIcon}");
          }
        `}</style>
      </div>
    );
  }
  
  componentDidMount() {
    // After mount, we can check authorization state and add/remove classes
    try {
      if (system && system.authSelectors) {
        const updateButtonState = () => {
          const isAuthorized = !!system.authSelectors.authorized().size;
          const button = document.querySelector('.custom-authorize-wrapper .authorize');
          if (button) {
            if (isAuthorized) {
              button.classList.add('locked');
              button.classList.remove('unlocked');
              document.body.className = 'sl-authorized';
            } else {
              button.classList.add('unlocked');
              button.classList.remove('locked');
              document.body.className = 'sl-not-authorized';
            }
          }
        };
        
        // Run once on mount
        updateButtonState();
        
        // Set up a MutationObserver to detect changes to the DOM
        // This helps catch auth state changes
        const observer = new MutationObserver(() => {
          updateButtonState();
        });
        
        // Start observing the document
        observer.observe(document.body, { 
          childList: true,
          subtree: true
        });
      }
    } catch (e) {
      console.error("Error setting up auth button:", e);
    }
  }
};

// Updated plugin
const AugmentingLayoutPlugin = () => {
  return {
    components: {
      AugmentingLayout: AugmentingLayout
    },
    wrapComponents: {
      authorizeBtn: CustomAuthorizeBtn
    }
  }
}

window.SwaggerUIBundle = (props) => {
  SwaggerUI({
    ...props,
    // url: 'https://userapi-staging.sellerlogic.dev/default/api-json',
    layout: "AugmentingLayout",
    plugins: [AugmentingLayoutPlugin],
  })
}
