import React from "react"

export const Logo = props => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="158" height="42" fill="none" viewBox="0 0 158 42">
    <g clipPath="url(#clip0)">
        <path fill="#05C" d="M17.491 24.207c-.283-.348-.637-.627-.92-.906-.92-.976-1.912-1.952-2.833-2.859-.212-.21-.425-.488-.708-.697-.142-.14-.212-.28-.212-.419v-.279c.212-1.255.354-2.58.566-3.835.142-.836 0-1.673-.708-2.091-.92-.628-2.195.07-2.337 1.255-.425 2.65-1.487 5.996.637 8.158 1.487 1.464 4.32 4.044 4.39 4.463.143.557-.07 1.115-.211 1.673-.213.837-.496 1.673-.709 2.51-.212.907-.212 1.813.567 2.37.92.629 2.054.07 2.408-1.045.354-1.325.779-2.65 1.133-3.975.354-1.603-.071-3.207-1.063-4.323zm5.807-2.789c-.283-1.046-1.558-1.743-2.337-2.3-.424-.35-.92-.698-1.345-1.047-.071-.07-.213-.14-.283-.209-.213-.14-.213-.627-.213-.627 0-.28.071-.558.071-.837.142-1.325.212-2.58.354-3.905.07-.767.142-1.604.212-2.37.213-2.302-2.903-2.65-3.115-.35-.142 1.395-.213 2.72-.354 4.115-.142 1.394-.496 3.068-.142 4.462.496 2.022 2.266 2.998 3.682 4.114.425.349.92.837 1.487 1.046 1.133.488 2.266-.767 1.983-2.092z"/>
        <path fill="#000" d="M16.57 2.94c.284 0 .567.14.78.35l11.613 10.807c.921.837 1.488 2.162 1.488 3.486v7.252c0 1.395-.567 2.65-1.63 3.556l-11.4 10.32c-.213.21-.567.349-.921.349-.354 0-.637-.14-.85-.35L4.107 28.392c-.991-.906-1.628-2.231-1.628-3.556v-7.252c0-1.324.566-2.58 1.487-3.416L15.65 3.359c.284-.28.567-.419.92-.419zm0-2.44c-.92 0-1.911.349-2.62 1.046L2.267 12.354C.78 13.748 0 15.63 0 17.584v7.251c0 2.022.85 3.974 2.408 5.37L13.95 40.523c.708.627 1.628.976 2.549.976.92 0 1.841-.349 2.55-.976l11.542-10.32C32.15 28.81 33 26.927 33 24.835v-7.252c0-1.952-.85-3.904-2.266-5.23L19.12 1.547C18.412.849 17.491.5 16.57.5z"/>
        <path fill="#05C" d="M50 23.5c0 2.04-1.62 4-4.832 4-1.476 0-2.952-.34-4.168-1.105l.781-1.614c1.13.68 2.171 1.02 3.474 1.02 1.562 0 2.517-.765 2.517-1.955 0-.934-.607-1.529-1.91-1.869l-1.389-.34c-1.129-.34-1.997-.764-2.431-1.53-.347-.509-.521-1.104-.521-1.783 0-2.21 1.736-3.824 4.341-3.824 1.476 0 2.952.595 3.994 1.36l-.955 1.444c-1.215-.68-1.997-.935-3.039-.935-1.215 0-2.084.68-2.084 1.7 0 .764.435 1.19 1.737 1.53l1.476.424C48.814 20.448 50 21.63 50 23.5zm5-2h3v-2h-3v-3h4.74l.26-2h-7v13h7v-2h-5v-4zm10-7h-2.012v13H69.5l.5-2h-5v-11zm10 0h-2.012v13H79.5l.5-2h-5v-11zm13.991 7v-2H85v-3h4.772l.228-2h-7v13h7v-2h-5v-4h3.991zm10.26 6l-1.128-2.21c-.869-1.528-1.476-2.378-2.17-3.058-.261-.255-.519-.34-.953-.34V27.5h-2v-13h3.907c2.865 0 4.093 1.87 4.093 3.824 0 1.784-1.14 3.398-3.05 3.398.433.255 1.301 1.445 1.996 2.464l1.91 3.314h-2.605zm-3.065-7c1.128 0 1.763-.307 2.11-.647.348-.34.704-.85.704-1.53 0-1.274-.79-1.869-2.7-1.869H95V20.5h1.186z"/>
        <path fill="#000" d="M108 25.5h5l-.488 2H106l.033-13h2l-.033 11zm25.698-3.353H136v3.229c-.521.255-1.305.425-2 .425-.955 0-1.778-.34-2.299-.935-.608-.68-.956-1.954-.956-3.654 0-2.889.911-4.712 3.255-4.712.955 0 1.781.04 2.65.719l1.042-1.275c-1.042-.85-2.216-1.444-3.692-1.444-1.216 0-2.386.425-3.255 1.105-1.562 1.19-2.431 2.973-2.431 5.522 0 1.955.434 3.4 1.39 4.504.955 1.19 2.473 1.869 4.296 1.869 1.389 0 2.781-.32 3.997-1v-6h-4.734l.435 1.647zm8.248 5.353h2.171v-13h-2.171v13zm13.632-2.464c-.695.595-1.389.765-2.258.765-1.215 0-2.257-.595-2.865-1.785-.347-.68-.521-1.402-.521-2.931 0-1.445.261-2.677.695-3.441.521-.935 1.476-1.53 2.518-1.53.781 0 1.476.255 2.17.68l.955-1.36c-.781-.594-1.91-.934-3.125-.934-1.997 0-3.734 1.105-4.689 2.889-.608 1.02-.868 2.166-.868 3.696 0 1.784.521 3.441 1.476 4.546.955 1.19 2.362 1.869 4.012 1.869 1.389 0 2.5-.425 3.455-1.19l-.955-1.274z"/>
        <path fill="#000" fillRule="evenodd" d="M125.593 21c0 3.909-1.91 6.457-5.21 6.457-3.646 0-5.383-2.718-5.383-6.457 0-4.078 2.171-6.457 5.296-6.457 3.56 0 5.297 2.803 5.297 6.457zm-5.383-4.843c2.344 0 2.952 1.87 2.952 4.928 0 3.399-.955 4.758-2.866 4.758-2.17 0-3.039-1.784-3.039-5.013 0-2.974.782-4.673 2.953-4.673z" clipRule="evenodd"/>
    </g>
    <defs>
        <clipPath id="clip0">
            <path fill="#fff" d="M0 0H158V41H0z" transform="translate(0 0.5)"/>
        </clipPath>
    </defs>
</svg>
)


export const UnlockIcon = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
        <path fill="#666" d="M13.714 7.145H4.786v-4c0-.552.448-1 1-1h4.428c.552 0 1 .448 1 1V4.36c0 .079.065.143.143.143h1c.079 0 .143-.064.143-.143V3.145C12.5 1.883 11.477.86 10.214.86H5.786C4.523.86 3.5 1.883 3.5 3.145v4H2.286c-.316 0-.572.255-.572.572v6.857c0 .316.256.571.572.571h11.428c.316 0 .572-.255.572-.571V7.717c0-.317-.255-.572-.572-.572zM13 13.86H3V8.431h10v5.428zm-5.5-2.482v.947c0 .078.064.143.143.143h.714c.079 0 .143-.065.143-.143v-.947c.148-.106.258-.256.314-.428.057-.172.058-.358.002-.53-.055-.174-.164-.324-.311-.431-.147-.107-.323-.165-.505-.165-.181 0-.358.058-.505.165-.146.107-.255.257-.311.43-.056.173-.055.359.002.531.057.172.167.322.314.428z"/>
    </svg>
)

export const ArrowIcon = props => (
    <svg className={'arrow ' + props.className} width="20" height="20"><use href="#large-arrow-down"></use></svg>
)

export const UnlockIconHTML = `
    <svg class="sl-unlock-icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
        <path fill="#666" d="M13.714 7.145H4.786v-4c0-.552.448-1 1-1h4.428c.552 0 1 .448 1 1V4.36c0 .079.065.143.143.143h1c.079 0 .143-.064.143-.143V3.145C12.5 1.883 11.477.86 10.214.86H5.786C4.523.86 3.5 1.883 3.5 3.145v4H2.286c-.316 0-.572.255-.572.572v6.857c0 .316.256.571.572.571h11.428c.316 0 .572-.255.572-.571V7.717c0-.317-.255-.572-.572-.572zM13 13.86H3V8.431h10v5.428zm-5.5-2.482v.947c0 .078.064.143.143.143h.714c.079 0 .143-.065.143-.143v-.947c.148-.106.258-.256.314-.428.057-.172.058-.358.002-.53-.055-.174-.164-.324-.311-.431-.147-.107-.323-.165-.505-.165-.181 0-.358.058-.505.165-.146.107-.255.257-.311.43-.056.173-.055.359.002.531.057.172.167.322.314.428z"/>
    </svg>`

export const LockIconHTML = `
    <svg class="sl-lock-icon" width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.5713 10.7175H18.7499V4.71754C18.7499 2.82379 17.215 1.28897 15.3213 1.28897H8.67843C6.78468 1.28897 5.24986 2.82379 5.24986 4.71754V10.7175H3.42843C2.95432 10.7175 2.57129 11.1006 2.57129 11.5747V21.8604C2.57129 22.3345 2.95432 22.7175 3.42843 22.7175H20.5713C21.0454 22.7175 21.4284 22.3345 21.4284 21.8604V11.5747C21.4284 11.1006 21.0454 10.7175 20.5713 10.7175ZM7.17843 4.71754C7.17843 3.88986 7.85075 3.21754 8.67843 3.21754H15.3213C16.149 3.21754 16.8213 3.88986 16.8213 4.71754V10.7175H7.17843V4.71754ZM19.4999 20.789H4.49986V12.6461H19.4999V20.789ZM11.2499 17.0658V18.4854C11.2499 18.6033 11.3463 18.6997 11.4641 18.6997H12.5356C12.6534 18.6997 12.7499 18.6033 12.7499 18.4854V17.0658C12.971 16.907 13.136 16.6822 13.2212 16.4237C13.3064 16.1652 13.3074 15.8863 13.224 15.6272C13.1406 15.3681 12.9772 15.1421 12.7572 14.9818C12.5372 14.8215 12.2721 14.7351 11.9999 14.7351C11.7277 14.7351 11.4625 14.8215 11.2425 14.9818C11.0225 15.1421 10.8591 15.3681 10.7757 15.6272C10.6923 15.8863 10.6933 16.1652 10.7785 16.4237C10.8637 16.6822 11.0288 16.907 11.2499 17.0658Z" fill="#666666"/>
    </svg>`